import React from "react";
import Card from "@material-ui/core/Card";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {BitvavoSettings} from "../factories/BitvavoSettings";

export interface AppSettingsProps {
    save: (setting: BitvavoSettings) => void;
    settings: BitvavoSettings;
}

export interface AppSettingsState {
    settings: BitvavoSettings;
    ipInfo: IpInfo;
}

export class IpInfo {
    ipAddress: string;
    continentCode: string;
    continentName: string;
    countryCode: string;
    countryName: string;
    stateProv: string;
    city: string;    
}

export class AppSettingsModal extends React.Component<AppSettingsProps, AppSettingsState> {
    constructor(props: any, state: any) {
        super(props, state);

        this.state = {
            settings: props.settings,
            ipInfo: null
        }

        fetch('https://api.db-ip.com/v2/free/self')
            .then(res => res.json())
            .then((data: IpInfo) => {
                this.setState({
                    ipInfo: data
                });
            });
    }

    saveOnClick = () => {
        this.props.save(
            this.state.settings
        );
    }

    inputOnChange = (e: any) => {
        let settings: any = this.state.settings || {};
        settings[e.target.name] = e.target.value;

        this.setState({
            settings: settings
        });
    }

    render() {
        let canSave = this.state.settings && this.state.settings.apiKey && this.state.settings.apiSecret;


        let ipAddress = this.state.ipInfo ? "(" + this.state.ipInfo.ipAddress + ")" : "";

        return (
            <Card className="card">
                <h3>Bitvavo API</h3>
                <TextField id="api-key" name="apiKey" label="API-Sleutel" onChange={this.inputOnChange} fullWidth variant="outlined" required={true} margin="normal" />
                <TextField id="api-secret" name="apiSecret" type="password" label="API-Geheim" fullWidth onChange={this.inputOnChange} variant="outlined" required={true} margin="normal"/><br />

                <div>
                    <a href='https://support.bitvavo.com/l/nl/article/on9e1brlqq-wat-zijn-api-keys-en-hoe-maak-ik-deze-aan' target='_blank'>Link: Hoe kom ik aan deze gegevens?</a><br />
                    <br />
                    <i>Let op: Zorg ervoor dat de sleutel alleen <b>LEESRECHTEN</b> heeft. Daarnaast kunt u uw eigen ip-adres {ipAddress} opgeven zodat alleen u de API sleutel kunt gebruiken. De opgegeven informatie wordt <u>in uw browser</u> versleuteld bewaard.</i>
                </div>

                <div className="card-buttons">
                    <Button variant="contained" color="primary" onClick={this.saveOnClick} disabled={! canSave}>Opslaan</Button>
                </div>
            </Card>
        )
    }
}