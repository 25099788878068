import {EventEmitter} from "events";
import {BitvavoSettings} from "./BitvavoSettings";

const bitvavo = require('bitvavo');

export interface Balance {
    symbol: string;
    available: number;
    inOrder: number;
}

export interface TickerPrice {
    market: string;
    price: number;
}

export interface Trade {
    id: string;
    orderId: string;
    timestamp: number;
    market: string;
    side: string;
    amount: number;
    price: number;
    taker: boolean;
    fee: string;
    feeCurrency: string;
    settled: boolean;
}

export interface WithdrawalHistory {
    timestamp: number;
    symbol: string;
    amount: number;
    address: string;
    paymentId: string;
    txId: string;
    fee: string;
    status: string;
}

export interface DepositHistory {
    timestamp: number;
    symbol: string;
    amount: number;
    address: string;
    paymentId: string;
    txId: string;
    fee: string;    
}

export interface BitvavoClient {
    balance(options: any): Promise<Balance[]>;
    tickerPrice(options: any): Promise<TickerPrice[]>;
    depositHistory(options: any): Promise<DepositHistory[]>;
    withdrawalHistory(options:any): Promise<WithdrawalHistory[]>;
    trades(market: string): Promise<Trade[]>;
    getEmitter(): EventEmitter;
    
    websocket: BitvavoClient;
}

export class BitvavoFactory {
    
    static client = new bitvavo();
    
    get(settings: BitvavoSettings): BitvavoClient {
        BitvavoFactory.client.options({
            APIKEY: settings.apiKey,
            APISECRET: settings.apiSecret,
            ACCESSWINDOW: 10000,
            RESTURL: 'https://api.bitvavo.com/v2',
            WSURL: 'wss://ws.bitvavo.com/v2/'
        });
        
        return BitvavoFactory.client;
    }
}

