import cookie from "react-cookies";
import {BitvavoSettings} from "./BitvavoSettings";

const Cryptr = require('cryptr');

export class BitvavoSettingsStore {
    private storeKey: string = 'e37f0698-01db-420d-b2c6-f5689155cbc5';

    hasSettings(): boolean {
        return cookie.load(this.storeKey) != null;
    }

    getSettings(secretKey: string): BitvavoSettings {
        const cryptr = new Cryptr(secretKey);

        let settingsEncrypted = cookie.load(this.storeKey);
        if (settingsEncrypted) {
            try {
                const settings = cryptr.decrypt(settingsEncrypted);

                return JSON.parse(settings);
            } catch {
            }

            throw "Onjuist wachtwoord opgegeven";
        }

        return new BitvavoSettings();
    }

    saveSettings(settings: BitvavoSettings, secretKey: string) {
        const cryptr = new Cryptr(secretKey);

        let settingsJson = JSON.stringify(settings);
        let settingsEncrypted = cryptr.encrypt(settingsJson);

        cookie.save(this.storeKey, settingsEncrypted, {
            path: '/',
            secure: true,
            maxAge: 2147483647
        });
    }

    clearSettings() {
        cookie.remove(this.storeKey);
    }
}