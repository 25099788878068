import React from 'react';
import './App.css';
import {BitvavoFactory} from "../factories/BitvavoFactory";
import {WalletOverview} from "./WalletOverview";
import {AppSettingsModal} from "./AppSettings";
import {AppPassword} from "./AppPassword";
import {AppBar, Button} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {BitvavoSettingsStore} from "../factories/BitvavoSettingsStore";
import {BitvavoSettings} from "../factories/BitvavoSettings";

interface AppState {
    settings: BitvavoSettings;
    hasSavedSettings: boolean;
}

interface AppProps {
}

export class App extends React.Component<AppProps, AppState> {
    
    private settingsStore: BitvavoSettingsStore = new BitvavoSettingsStore();
    
    
    constructor(props: any, state: any) {
        super(props, state);
        
        let hasSettings = this.settingsStore.hasSettings();
        
        this.state = {
            hasSavedSettings: hasSettings,
            settings: null
        }        
    }
    
    saveSettings = (settings: BitvavoSettings) => {
        this.setState({
            settings: settings
        });
    }

    onEnterPassword = (password: string) => {
        let {settings, hasSavedSettings} = this.state;
        
        if (! hasSavedSettings) {
            this.settingsStore.saveSettings(settings, password);
            
            this.setState({
                hasSavedSettings: true
            });
        } else {
            
            try {
                settings = this.settingsStore.getSettings(password);

                this.setState({
                    settings: settings
                });
            } catch(e) {
                alert(e);
            }
        }
    }
    
    clearSavedSettings = () => {
        this.settingsStore.clearSettings();
        
        this.setState({
            hasSavedSettings: false,
            settings: null
        });
    }

    render() {

        let client = this.state.hasSavedSettings && this.state.settings ? new BitvavoFactory().get(this.state.settings!) : null;
        
        let askPassword = (this.state.settings && !this.state.hasSavedSettings) || (this.state.hasSavedSettings && ! this.state.settings);        
        let askPasswordText = this.state.hasSavedSettings ? "Geef uw wachtwoord/pincode op om uw account instellingen op te halen" : "Stel een wachtwoord/pincode in om uw account gegevens te beveiligen";
      
        return (
            
            <div className="App">
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h5" component="h3">
                            Portfolio
                        </Typography>

                        {this.state.hasSavedSettings && <Button color="inherit" style={{'position':'absolute', 'right': '10px'}} onClick={this.clearSavedSettings}>Verwijder account</Button>  }
                    </Toolbar>
                </AppBar>
                
                <div className="container">
                        
                    {!this.state.settings && ! this.state.hasSavedSettings &&
                        <AppSettingsModal
                            save={this.saveSettings}
                            settings={this.state.settings}/> }

                    {askPassword &&
                        <AppPassword 
                            callback={this.onEnterPassword} 
                            title={askPasswordText} />
                    }
                        
                    { client && 
                        <WalletOverview bitvavoClient={client} /> }                    
                    
                </div>          
            </div>
        );
  }
}


