import React from 'react';
import './App.css';
import {
    Balance,
    BitvavoClient,
    DepositHistory,
    TickerPrice,
    Trade,
    WithdrawalHistory
} from "../factories/BitvavoFactory";
import {DataGrid, GridCellParams} from '@material-ui/data-grid';
import Paper from "@material-ui/core/Paper";
import {Format} from "../utils/Format";
import {FormControlLabel} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

class WalletState {
    public balance: Balance[] = [];
    public ticker: TickerPrice[] = [];
    public depositHistory: DepositHistory[] = [];
    public withdrawalHistory: WithdrawalHistory[] =[]
    public trades: Trade[] = [];
    public rows: GridRow[] = [];
    public compactMode: boolean;
}

interface WalletProps {
    bitvavoClient: BitvavoClient;
}

interface GridRow extends Balance {
    price: number;
    averagePrice: number;

    totalBalance: number;
    totalInvested: number;

    withdrawals: WithdrawalHistory[];
    withdrawalSum: number;
    depositSum: number;
    availableWallet: number;
    deposits: DepositHistory[];
    performance: number;
}

export class WalletOverview extends React.Component<WalletProps, WalletState> {

    constructor(props: any, state: any) {
        super(props, state);

        this.state = {
            balance: [],
            ticker: [],
            trades: [],
            depositHistory: [],
            withdrawalHistory: [],
            rows: [],
            compactMode: true
        }
    }

    componentDidMount() {
        this.bindEvents();
        this.loadData();
    }
    
    bindEvents() {
        let client = this.props.bitvavoClient;
        let emitter = client.getEmitter();

        emitter.on('balance', this.onBalanceCallback);
        emitter.on('error', this.onErrorCallback);
        emitter.on('trades', this.onTradesCallback);
        emitter.on('trades', this.onTradesCacheCallback);
        emitter.on('withdrawalHistory', this.onWithdrawalCallback);
        emitter.on('depositHistory', this.onDepositCallback);
        emitter.on('tickerPrice', this.onTickerPriceCallback);

        this.tickerTimer(client);
    }

    loadData() {
        let client = this.props.bitvavoClient;

        client.websocket.balance({});
        client.websocket.withdrawalHistory({});
        client.websocket.depositHistory({});
    }
    
    onBalanceCallback = (response: Balance[]) => {

        // retrieve trades
        response.forEach(c => {

            c.available = Number(c.available);
            c.inOrder = Number(c.inOrder);
            
            let market = c.symbol + "-EUR";

            let sessionItem = sessionStorage.getItem('trade-' + market);
            if (sessionItem) {
                this.onTradesCallback(JSON.parse(sessionItem));
            } else {
                sessionStorage.setItem('trade-' + market, '[]');
                this.props.bitvavoClient.websocket.trades(market);
            }
        });

        this.setState({
            balance: response,
            rows: response.map<GridRow>(c => ({
                ...c,
                withdrawalSum: 0,
                totalInvested: 0,
                averagePrice: 0,
                depositSum: 0,
                deposits: [],
                availableWallet: 0,
                withdrawals: [],
                price: 0,
                totalBalance: 0,
                performance: 0
            }))
        });
    }

    onDepositCallback = (response: DepositHistory[]) => {
        response.forEach(c => {
            c.amount = Number(c.amount);
        });

        this.setState({depositHistory: response});
    }
    
    onErrorCallback = (response: any) => {
        console.log('Handle errors here', response);

        if (response.action == 'authenticate') {
            // most likely auth failed within reasonable time, retry it
            this.loadData();
        } else {
            console.error(response);
        }
    }
    
    onTradesCallback = (response: Trade[]) => {
        response.forEach(c => {
            c.amount = Number(c.amount);
            c.price = Number(c.price);
        });

        this.setState({
            trades: [...this.state.trades, ...response]
        });
    }
    
    onTradesCacheCallback = (response: Trade[]) => {
        if (response.length > 0) {
            sessionStorage.setItem('trade-' +response[0].market, JSON.stringify(response));
        }
    }
    
    onTickerPriceCallback = (response: TickerPrice[]) => {
        response.forEach(c => {
            c.price = Number(c.price)
        });

        this.setState({
            ticker: response
        });
    }
    
    onWithdrawalCallback = (response: WithdrawalHistory[]) => {
        response.forEach(c => {
            c.amount = Number(c.amount)
        });

        this.setState({withdrawalHistory: response});
    }

    tickerTimer(client: BitvavoClient) {
        client.websocket.tickerPrice({});

        setTimeout(() => this.tickerTimer(client), 5000);
    }

    updateTicker() {
        if (this.state.rows && this.state.ticker) {
            this.state.ticker.forEach((ticker) => {
                if (ticker.market.indexOf('EUR') == -1)
                    return;

                let rows = this.state.rows.filter(c => (c.symbol + "-EUR") == ticker.market);
                if (rows.length > 0) {
                    rows[0].price = ticker.price;
                }
            });
        }
    }

    updateTrades() {
        this.state.rows.forEach((row) => {
            if (! ((row.available + row.inOrder + row.withdrawalSum) > 0))
                return;

            let totalValue = 0;
            let totalCoins = 0;

            this.state.trades.filter(c => c.market == row.symbol + "-EUR").reverse().forEach((trade) => {

                switch (trade.side) {
                    case "buy": 
                        totalValue += trade.amount * trade.price + Number(trade.fee);
                        totalCoins += trade.amount;
                        break;
                    case "sell": 
                        totalValue -= trade.amount * trade.price + Number(trade.fee);
                        totalCoins -= trade.amount;
                        break;
                    default:
                        console.log("unknown trade side" + trade.side);
                        console.log(trade)
                        break;                        
                }

                if (totalValue < 0) {
                    totalValue = totalCoins * trade.price;
                }
                
                // reset in case los or profit has ben taken
                if (totalCoins == 0) {
                    totalValue = 0;
                }
            });

            row.totalInvested = totalValue;
            row.averagePrice = totalValue / totalCoins;
            row.totalBalance = Math.round(row.price * totalCoins * 100) / 100;
            row.performance = Math.round(row.price / row.averagePrice  * 100) - 100;
        });
    }

    private updateWithdrawals() {

        this.state.rows.forEach((row) => {
            let deposits = this.state.depositHistory.filter(c => c.symbol == row.symbol);
            let withdrawals = this.state.withdrawalHistory.filter(c => c.symbol == row.symbol);

            row.withdrawals = withdrawals;
            row.deposits = deposits;

            row.depositSum = deposits.reduce((a, b) => a + b.amount, 0);
            row.withdrawalSum = withdrawals.reduce((a, b) => a + b.amount, 0);
            
            row.availableWallet = row.withdrawalSum - row.depositSum;
        });
    }
    
    private onToggleCompactMode = (e: any) => {
        this.setState({
            compactMode: e.target.checked
        });
    }

    render() {

        this.updateTicker();
        this.updateTrades();
        this.updateWithdrawals();

        let columns = [{
                field: 'symbol',
                headerName: 'Munt',
                width: 120,
            },
            {
                field: 'price',
                type: 'number',
                headerName: 'Koers',
                width: 130,
                valueFormatter: (params: any) => Format.currencyAuto(params.value)
            },
            {
                field: 'available',
                headerName: 'Beschikbaar',
                hide: this.state.compactMode,
                width: 200,
                type: 'number',
                valueFormatter: (params: any) => {
                    return Math.round(Number(params.value) * 100) / 100;
                }            
            },
            {
                field: 'inOrder',
                headerName: 'In order',
                hide: this.state.compactMode,
                width: 200,
                type: 'number'
            },
            {
                field: 'averagePrice',
                type: 'number',
                headerName: 'Aanschaf (Gemid)',
                hide: this.state.compactMode,
                width: 200,
                valueFormatter: (params: any) => Format.currencyAuto(params.value)
            },
            {
                field: 'totalInvested',
                type: 'number',
                headerName: 'Investering',
                hide: this.state.compactMode,
                width: 200,
                valueFormatter: (params: any) => Format.currencyAuto(params.value)
            },            
            {
                field: 'availableWallet',
                type: 'number',
                hide: this.state.compactMode,
                headerName: 'Wallet',
                width: 150
            },            
            {
                field: 'performance',
                type: 'number',
                headerName: '+ / -',
                width: 120,
                renderCell: (params: GridCellParams) => {
                    let number = Number(params.value);

                    if (number < 0) {
                        return <div className="sale loss">- {Math.abs(number)} %</div>
                    } else {
                        return <div className="sale profit">+ {number} %</div>
                    }
                },
            },
            {
                field: 'totalBalance',
                type: 'number',
                headerName: 'Waarde',
                width: 200,
                valueFormatter: (params: any) => Format.currency(params.value)
            }];

        let displayRows = this.state.rows.filter(c => (c.withdrawalSum + c.inOrder + c.available) > 0 && c.symbol != "EUR");
        let totalBalance = displayRows.reduce((a, b) => a + b.totalBalance, 0);
        let totalInvested = this.state.depositHistory.filter(c => c.symbol == "EUR").reduce((a,b ) => a + b.amount, 0) - this.state.withdrawalHistory.filter(c => c.symbol == "EUR").reduce((a,b ) => a + b.amount, 0);

        return (
            <div className="portfolio">
                <Paper style={{'padding': '5px 20px 5px 20px', 'margin': '-10px 0 10px 0'}}>
                    <p>
                        Balans: { Format.currency(totalBalance) } (Investering: { Format.currency(totalInvested)})
                        <FormControlLabel
                            style={{ float: 'right', 'margin': '-7px'}}
                            control={<Switch checked={this.state.compactMode} onChange={this.onToggleCompactMode}  />}
                            label="Compact"
                        />
                    </p>
                </Paper>
                
                <DataGrid
                    rows={displayRows}
                    columns={columns}
                    density={"compact"}                    
                    getRowId={(item) => item.symbol}
                    disableSelectionOnClick
                    hideFooter={true}
                />
            </div>
        );
    }
}