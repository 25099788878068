export class Format {
    
    public static currencyAuto = (value: number) => {
        let fractionDigits = 2;
        
        if (value < 10) fractionDigits = 3;
        if (value < 0.1) fractionDigits = 4;
        if (value < 0.01) fractionDigits = 5;
        if (value == 0)  fractionDigits = 2;
        
        return Format.currency(value, fractionDigits);        
    }    
    
    public static currency = (value: number, fractionDigits = 2) => {
        return new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: fractionDigits
        }).format(value);
    }
}