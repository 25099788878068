import React from "react";
import Card from "@material-ui/core/Card";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export interface AppPasswordProps {
    title: string;
    callback: (password: string) => void;
}

export interface AppPasswordState {
    password?: string;
}

export class AppPassword extends React.Component<AppPasswordProps, AppPasswordState> {


    constructor(props: AppPasswordProps, state: AppPasswordState) {
        super(props, state);

        this.state ={};
    }

    onChangePassword = (e: any) => {
        this.setState({
            password: e.target.value
        });
    }

    onKeyPress = (e: any) => {
        if (e.key == 'Enter') {
            this.onSubmitPassword()
        }
    }

    onSubmitPassword = () => {
        this.props.callback(this.state.password!);
    }

    render() {
        return (
            <Card className="card">
                <div>{this.props.title}</div>
                <TextField 
                    type="password" 
                    fullWidth 
                    id="encryption-key" 
                    name="encryptionKey" 
                    label="Wachtwoord"
                    autoFocus
                    onChange={this.onChangePassword} 
                    onKeyPress={this.onKeyPress} 
                    variant="outlined" 
                    required 
                    margin="normal" />
                <div className="card-buttons">
                    <Button disabled={! this.state.password} color="primary" onClick={this.onSubmitPassword}>Ok</Button>
                </div>
            </Card>);
    }
}